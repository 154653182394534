import React from 'react';
import { 
  Grid,
  Image,
  Header,
} from "semantic-ui-react";

// Components
import RequestCatalog from './RequestCatalog';

const App = () => {
  return (
    <Grid container>
      <Grid.Row>
        <Grid.Column computer={3} only="computer">
        </Grid.Column>
        <Grid.Column computer={10} mobile={16} tablet={16}>
          <Grid>

            <Grid.Row>
              <Grid.Column width={16}>
                <div style={{minHeight: 80}} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Image 
                  size="small"
                  src="https://res.cloudinary.com/saiyean/image/upload/v1628096103/Shawn-Public/1011logo_white.png"
                  centered
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center" width={16}>
                  <Header as="h1" style={{fontSize: '40px', color: "#FFFFFF", fontFamily: 'Lato', letterSpacing: '0.3em'}}>
                    COMING SOON
                  </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div style={{fontSize: '20px', color: "#FFFFFF", textAlign: "center", lineHeight: "1.5", fontFamily: 'Roboto'}}>
                  We are currently working to bring you a better digital experience. 
                  In the meantime, feel free to request for our catalogs to learn more about our services.
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center" width={16}>
                  <RequestCatalog />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div style={{minHeight: 20}} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div style={{fontSize: '15px', color: "#FFFFFF", textAlign: "center", lineHeight: "1.5", fontFamily: 'Roboto'}}>
                  <a className="custom-anchor" href="https://www.facebook.com/1011production">FACEBOOK</a> | <a href="https://www.instagram.com/1011production/">INSTAGRAM</a> | <a href="mailto:hello@1011production.com">HELLO@1011PRODUCTION.COM</a>
                </div>
                </Grid.Column>
            </Grid.Row>

          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default App;
