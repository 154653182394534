import React, {useState,} from 'react';
import {
    Modal,
    Button,
    Message,
    Form,
} from 'semantic-ui-react';
import Moment from 'moment';
import _ from 'lodash';
import Axios from 'axios';

const emailRegExp = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");

const getYearOptions = () => {
    const today = Moment();
    const currentYear = today.year();
    return [
        { key: `${currentYear}`, text: `${currentYear}`, value: `${currentYear}`},
        { key: `${currentYear + 1}`, text: `${currentYear + 1}`, value: `${currentYear + 1}`},
        { key: `${currentYear + 2}`, text: `${currentYear + 2}`, value: `${currentYear + 2}`},
        { key: `${currentYear + 3}`, text: `${currentYear + 3}`, value: `${currentYear + 3}`},
    ];
};

const monthOptions = [
    { key: 0, text: 'January', value: 'January', },
    { key: 1, text: 'February', value: 'February', },
    { key: 2, text: 'March', value: 'March', },
    { key: 3, text: 'April', value: 'April', },
    { key: 4, text: 'May', value: 'May', },
    { key: 5, text: 'June', value: 'June', },
    { key: 6, text: 'July', value: 'July', },
    { key: 7, text: 'August', value: 'August', },
    { key: 8, text: 'September', value: 'September', },
    { key: 9, text: 'October', value: 'October', },
    { key: 10, text: 'November', value: 'November', },
    { key: 11, text: 'December', value: 'December', },
];

const getCurrentMonth = () => {
    const currentMonth = Moment().month();
    if (currentMonth < monthOptions.length) {
        return monthOptions[currentMonth].value;
    } else {
        return monthOptions[0].value;
    }
};

const validateEmailString = (email) => {  
    return emailRegExp.test(email);
};

const RequestCatalog = (props) => {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [complete, setComplete] = useState(false);
    
    const yearOptions = getYearOptions();

    const [request, setRequest] = useState({
        name: "",
        email: "",
        venue: "",
        month: getCurrentMonth(),
        year: yearOptions[0].value,
    });

    const handleChange = (e, { name, value }) => {
        setRequest(Object.assign({}, request, {[name]: value}));
    };

    const handleSubmit = async () => {
        try {
            setError(null);
            // console.log('request->', request);
            // Validation
            const cMth = Moment().month();
            const cYear = Moment().year();
            let selectedMthIndex = _.find(monthOptions, (o) => o.value === request.month);
            selectedMthIndex = selectedMthIndex.key;
            // console.log(cMth, cYear, selectedMthIndex, request.year);
            // console.log(request.year === `${cYear}`, selectedMthIndex < cMth);
            if (request.year === `${cYear}` && selectedMthIndex < cMth) {
                throw new Error('Tentative wedding date cannot be before today\'s date')
            }
            if (request.name === "") throw new Error('Name cannot be empty');
            if (request.email === "") throw new Error('Email cannot be empty');
            if (validateEmailString(request.email) === false) throw new Error('Invalid email format');
            if (request.venue === "") throw new Error('Venue cannot be empty');


            // Start Form Submission
            setLoading(true);
            // const r = await Axios.post('http://localhost:4000/catalog-request', request);
            const r = await Axios.post('https://api.1011production.com/catalog-request', request);
            // console.log(r);
            if (r && r.status && r.status === 200) {
                setComplete(true);
            } else {
                setLoading(false);
                setError('There is an issue with submitting your form, please try again later or email us at hello@1011production.com');
            }
        } catch (error) {
            setLoading(false);
            setError(error.message);
        };
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button size="large">Request Catalog</Button>}
            size="small"
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                {(complete) && (
                    <Modal.Description>
                        <Message success>
                            <Message.Header>We have received your request submission!</Message.Header>
                            <p>
                                Kindly give us some time for our friendly staff assistants to contact you further!
                            </p>
                        </Message>
                        <Button fluid primary onClick={() => setOpen(false)}>
                            Back
                        </Button>
                    </Modal.Description>
                )}
                {(complete === false) && (
                    <Modal.Description>
                        <Message info>
                            <Message.Header>Request Catalog</Message.Header>
                            Please fill in the information below for us to serve you better!
                        </Message>
                        <Form onSubmit={handleSubmit} error={(error) ? true : false}>
                            <Form.Group widths='equal'>
                                <Form.Input name='name' fluid label='Name' value={request.name} onChange={handleChange} disabled={loading}/>
                                <Form.Input name='email' fluid label='Email' value={request.email} onChange={handleChange} disabled={loading}/>
                            </Form.Group>
                            <Form.Input name='venue' fluid label='Tentative Venue' value={request.venue} onChange={handleChange} disabled={loading}/>
                            <Form.Group widths="equal">
                                <Form.Select
                                    fluid
                                    label='Tentative Wedding Month'
                                    options={monthOptions}
                                    value={request.month} 
                                    onChange={handleChange}
                                    name='month'
                                    disabled={loading}
                                />
                                <Form.Select
                                    fluid
                                    label='Tentative Wedding Year'
                                    options={yearOptions}
                                    value={request.year} 
                                    onChange={handleChange}
                                    name='year'
                                    disabled={loading}
                                />
                            </Form.Group>
                            <Message error>
                                    <Message.Header>Oops!</Message.Header>
                                    {error}
                                </Message>
                            <p>
                                &nbsp;
                            </p>
                            <div style={{float: "right"}}>
                            <Button onClick={() => setOpen(false)} disabled={loading}>
                                Cancel
                            </Button>
                            <Button
                                primary
                                content="Submit"
                                labelPosition='right'
                                icon='send'
                                type="submit"
                                loading={loading}
                            />
                            </div>
                            <p>
                                &nbsp;
                            </p>
                        </Form>
                    </Modal.Description>
                )}
                
            </Modal.Content>
      </Modal>
    );
};

export default RequestCatalog;